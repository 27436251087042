import { css } from "lit";

export default css`
  :host {
    fill: currentcolor;
    color: inherit;
    display: inline-flex;
    pointer-events: none;
    vertical-align: text-top;
  }

  :host(:not(:root)) {
    overflow: hidden;
  }

  @media (forced-colors: active) {
    .aileron-icon,
    :host {
      forced-color-adjust: auto;
    }
  }

  #container {
    height: 100%;
  }

  img,
  svg,
  ::slotted(*) {
    vertical-align: top;
  }

  svg path {
    fill: var(--icon-fill, var(--color-light-mode-icon-icon-interactive));
  }
`;
